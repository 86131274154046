import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [0,9];

export const dictionary = {
		"/": [~11],
		"/about-dubclub": [12],
		"/capper/manage-prices": [~13,[2],[3]],
		"/capper/profile/[name_or_price]": [~14],
		"/capper/referral": [15,[4]],
		"/capper/stream/[name]": [~16,[5]],
		"/footer": [17],
		"/product/new": [18],
		"/publish": [19],
		"/publish/sales": [~20,[6]],
		"/r/capper": [21],
		"/r/claim-name": [~22],
		"/r/discover-cappers": [23,[7]],
		"/r/discover-cappers/(discovery)/cappers": [24,[7,8]],
		"/r/discover-cappers/(discovery)/leagues": [25,[7,8]],
		"/r/discover-cappers/(discovery)/plays": [26,[7,8]],
		"/r/discover-cappers/(discovery)/search": [27,[7,8]],
		"/r/discover-cappers/(discovery)/sports": [28,[7,8]],
		"/t/[productKey]": [29,[9]],
		"/t/[productKey]/edit": [30],
		"/t/[productKey]/faqs": [31,[9]],
		"/t/[productKey]/notifications": [32,[9]],
		"/viewm/[marketing=lookup_key]/[user=lookup_key]": [~33,[10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';